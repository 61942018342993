#calltrap-btn {
  cursor: pointer;
  background-color: green;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  display: block;
  height: 64px;
  line-height: 100px;
  opacity: 1;
  position: fixed;
  /* right: 93px; */
  bottom: 105px;
  text-align: center;
  width: 64px;
  z-index: 9999;
  -webkit-animation: calltrap_opacity 3s infinite linear;
  -moz-animation: calltrap_opacity 3s infinite linear;
  -o-animation: calltrap_opacity 3s infinite linear;
  animation: calltrap_opacity 3s infinite linear
}
@media (max-width: 1200px) {
  #calltrap-btn {
    display: none;
  }
}

#calltrap-ico {
  width: 32px;
  height: 32px;
  position: absolute;
  margin: auto;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  border: 0;
  -webkit-border-radius: 50% !important;
  border-radius: 50% !important;
  -o-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-animation: uptocallmini 1.5s 0s ease-out infinite;
  -moz-animation: uptocallmini 1.5s 0s ease-out infinite;
  -o-animation: uptocallmini 1.5s 0s ease-out infinite;
  animation: uptocallmini 1.5s 0s ease-out infinite
}

#calltrap-ico:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  top: 0;
  left: 0;
  background-image: url("../../assets/icons/whatsapp.svg");
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-animation: calltrap_spin 3s infinite linear;
  -moz-animation: calltrap_spin 3s infinite linear;
  -o-animation: calltrap_spin 3s infinite linear;
  animation: calltrap_spin 3s infinite linear
}

@-webkit-keyframes calltrap_opacity {
  0% {
      opacity: 1
  }
  57% {
      opacity: 0.75
  }
  75% {
      opacity: 0.95
  }
  100% {
      opacity: 1
  }
}

@-moz-keyframes calltrap_opacity {
  0% {
      opacity: 1
  }
  57% {
      opacity: 0.75
  }
  75% {
      opacity: 0.95
  }
  100% {
      opacity: 1
  }
}

@-o-keyframes calltrap_opacity {
  0% {
      opacity: 1
  }
  57% {
      opacity: 0.75
  }
  75% {
      opacity: 0.95
  }
  100% {
      opacity: 1
  }
}

@keyframes calltrap_opacity {
  0% {
      opacity: 1
  }
  57% {
      opacity: 0.75
  }
  75% {
      opacity: 0.95
  }
  100% {
      opacity: 1
  }
}

@-webkit-keyframes uptocallmini {
  0% {
      -webkit-box-shadow: 0 0 8px 6px rgba(32, 116, 161, 0), 0 0 0px 0px transparent, 0 0 0px 0px rgba(32, 116, 161, 0);
      box-shadow: 0 0 8px 6px rgba(32, 116, 161, 0), 0 0 0px 0px transparent, 0 0 0px 0px rgba(32, 116, 161, 0)
  }
  10% {
      -webkit-box-shadow: 0 0 8px 6px green, 0 0 12px 10px transparent, 0 0 12px 14px green;
      box-shadow: 0 0 8px 6px green, 0 0 12px 10px transparent, 0 0 12px 14px green
  }
  100% {
      -webkit-box-shadow: 0 0 8px 6px rgba(32, 116, 161, 0), 0 0 0px 40px transparent, 0 0 0px 40px rgba(32, 116, 161, 0);
      box-shadow: 0 0 8px 6px rgba(32, 116, 161, 0), 0 0 0px 40px transparent, 0 0 0px 40px rgba(32, 116, 161, 0)
  }
}

@-moz-keyframes uptocallmini {
  0% {
      box-shadow: 0 0 8px 6px rgba(32, 116, 161, 0), 0 0 0px 0px transparent, 0 0 0px 0px rgba(32, 116, 161, 0)
  }
  10% {
      box-shadow: 0 0 8px 6px green, 0 0 12px 10px transparent, 0 0 12px 14px green
  }
  100% {
      box-shadow: 0 0 8px 6px rgba(32, 116, 161, 0), 0 0 0px 40px transparent, 0 0 0px 40px rgba(32, 116, 161, 0)
  }
}

@-o-keyframes uptocallmini {
  0% {
      box-shadow: 0 0 8px 6px rgba(32, 116, 161, 0), 0 0 0px 0px transparent, 0 0 0px 0px rgba(32, 116, 161, 0)
  }
  10% {
      box-shadow: 0 0 8px 6px green, 0 0 12px 10px transparent, 0 0 12px 14px green
  }
  100% {
      box-shadow: 0 0 8px 6px rgba(32, 116, 161, 0), 0 0 0px 40px transparent, 0 0 0px 40px rgba(32, 116, 161, 0)
  }
}

@keyframes uptocallmini {
  0% {
      -webkit-box-shadow: 0 0 8px 6px rgba(32, 116, 161, 0), 0 0 0px 0px transparent, 0 0 0px 0px rgba(32, 116, 161, 0);
      box-shadow: 0 0 8px 6px rgba(32, 116, 161, 0), 0 0 0px 0px transparent, 0 0 0px 0px rgba(32, 116, 161, 0)
  }
  10% {
      -webkit-box-shadow: 0 0 8px 6px green, 0 0 12px 10px transparent, 0 0 12px 14px green;
      box-shadow: 0 0 8px 6px green, 0 0 12px 10px transparent, 0 0 12px 14px green
  }
  100% {
      -webkit-box-shadow: 0 0 8px 6px rgba(32, 116, 161, 0), 0 0 0px 40px transparent, 0 0 0px 40px rgba(32, 116, 161, 0);
      box-shadow: 0 0 8px 6px rgba(32, 116, 161, 0), 0 0 0px 40px transparent, 0 0 0px 40px rgba(32, 116, 161, 0)
  }
}

@-webkit-keyframes calltrap_spin {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
  }
  30% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
  }
  33% {
      -webkit-transform: rotate(-10deg);
      transform: rotate(-10deg)
  }
  36% {
      -webkit-transform: rotate(10deg);
      transform: rotate(10deg)
  }
  39% {
      -webkit-transform: rotate(-10deg);
      transform: rotate(-10deg)
  }
  42% {
      -webkit-transform: rotate(10deg);
      transform: rotate(10deg)
  }
  45% {
      -webkit-transform: rotate(-10deg);
      transform: rotate(-10deg)
  }
  48% {
      -webkit-transform: rotate(10deg);
      transform: rotate(10deg);
      opacity: 1
  }
  51% {
      -webkit-transform: rotate(-10deg);
      transform: rotate(-10deg)
  }
  54% {
      -webkit-transform: rotate(10deg);
      transform: rotate(10deg)
  }
  57% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
  }
}

@-moz-keyframes calltrap_spin {
  0% {
      -moz-transform: rotate(0deg);
      transform: rotate(0deg)
  }
  30% {
      -moz-transform: rotate(0deg);
      transform: rotate(0deg)
  }
  33% {
      -moz-transform: rotate(-10deg);
      transform: rotate(-10deg)
  }
  36% {
      -moz-transform: rotate(10deg);
      transform: rotate(10deg)
  }
  39% {
      -moz-transform: rotate(-10deg);
      transform: rotate(-10deg)
  }
  42% {
      -moz-transform: rotate(10deg);
      transform: rotate(10deg)
  }
  45% {
      -moz-transform: rotate(-10deg);
      transform: rotate(-10deg)
  }
  48% {
      -moz-transform: rotate(10deg);
      transform: rotate(10deg);
      opacity: 1
  }
  51% {
      -moz-transform: rotate(-10deg);
      transform: rotate(-10deg)
  }
  54% {
      -moz-transform: rotate(10deg);
      transform: rotate(10deg)
  }
  57% {
      -moz-transform: rotate(0deg);
      transform: rotate(0deg)
  }
}

@-o-keyframes calltrap_spin {
  0% {
      -o-transform: rotate(0deg);
      transform: rotate(0deg)
  }
  30% {
      -o-transform: rotate(0deg);
      transform: rotate(0deg)
  }
  33% {
      -o-transform: rotate(-10deg);
      transform: rotate(-10deg)
  }
  36% {
      -o-transform: rotate(10deg);
      transform: rotate(10deg)
  }
  39% {
      -o-transform: rotate(-10deg);
      transform: rotate(-10deg)
  }
  42% {
      -o-transform: rotate(10deg);
      transform: rotate(10deg)
  }
  45% {
      -o-transform: rotate(-10deg);
      transform: rotate(-10deg)
  }
  48% {
      -o-transform: rotate(10deg);
      transform: rotate(10deg);
      opacity: 1
  }
  51% {
      -o-transform: rotate(-10deg);
      transform: rotate(-10deg)
  }
  54% {
      -o-transform: rotate(10deg);
      transform: rotate(10deg)
  }
  57% {
      -o-transform: rotate(0deg);
      transform: rotate(0deg)
  }
}

@keyframes calltrap_spin {
  0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg)
  }
  30% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg)
  }
  33% {
      -webkit-transform: rotate(-10deg);
      -moz-transform: rotate(-10deg);
      -o-transform: rotate(-10deg);
      transform: rotate(-10deg)
  }
  36% {
      -webkit-transform: rotate(10deg);
      -moz-transform: rotate(10deg);
      -o-transform: rotate(10deg);
      transform: rotate(10deg)
  }
  39% {
      -webkit-transform: rotate(-10deg);
      -moz-transform: rotate(-10deg);
      -o-transform: rotate(-10deg);
      transform: rotate(-10deg)
  }
  42% {
      -webkit-transform: rotate(10deg);
      -moz-transform: rotate(10deg);
      -o-transform: rotate(10deg);
      transform: rotate(10deg)
  }
  45% {
      -webkit-transform: rotate(-10deg);
      -moz-transform: rotate(-10deg);
      -o-transform: rotate(-10deg);
      transform: rotate(-10deg)
  }
  48% {
      -webkit-transform: rotate(10deg);
      -moz-transform: rotate(10deg);
      -o-transform: rotate(10deg);
      transform: rotate(10deg);
      opacity: 1
  }
  51% {
      -webkit-transform: rotate(-10deg);
      -moz-transform: rotate(-10deg);
      -o-transform: rotate(-10deg);
      transform: rotate(-10deg)
  }
  54% {
      -webkit-transform: rotate(10deg);
      -moz-transform: rotate(10deg);
      -o-transform: rotate(10deg);
      transform: rotate(10deg)
  }
  57% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg)
  }
}


